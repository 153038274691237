// React
import React, { useState, useEffect } from 'react';

// Packages
import t from 'prop-types';
import AnimateHeight from 'react-animate-height';
import cc from 'classcat';
import dayjs from 'dayjs';

// Emotion / styling
import { css } from '@emotion/react';
import s from 'src/styles';

// Utilities
import { useAccount } from 'hooks';

// Components
import Button from 'components/button';
import Summary from 'components/_cart/summary';
import BasketOverview from 'components/_cart/basketOverview';

const OrdersComponent = ({ labels, theme, locale }) => {
    // State for order "pages"
    const [pageCnt, setPageCnt] = useState(1);
    const [pages, setPages] = useState([]);
    const [morePages, setMorePages] = useState(true);

    useEffect(() => {
        setPages([
            ...pages,
            <OrdersPageComponent
                {...{
                    labels,
                    page: pageCnt,
                    setStatus(response) {
                        setMorePages(
                            response.pages
                                ? response.page <
                                      Object.keys(response.pages).length
                                : false
                        );
                    },
                }}
                key={pageCnt}
            />,
        ]);
    }, [pageCnt]);

    return (
        <div>
            <h3 className="mb-24 text-black t-h7 text-opacity-60">
                {labels.ACCOUNT.ORDERS.TITLE}
            </h3>
            <div className="my-24">{pages}</div>
            <div className="flex justify-end">
                {morePages && (
                    <Button
                        {...{
                            onClick() {
                                setPageCnt(pageCnt + 1);
                            },
                            theme,
                            label: labels.ACCOUNT.ORDERS.LOAD_MORE,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const OrdersPageComponent = ({ labels, theme, page, setStatus }) => {
    // Hook: useAccount
    const { getOrders } = useAccount();

    // Get orders
    const { data: ordersData } = getOrders(page, setStatus);

    return ordersData
        ? ordersData?.results?.map((order, index) => (
              <OrderDetailsComponent
                  {...{
                      order,
                      labels,
                      theme,
                      everyOther: index % 2 === 0,
                  }}
                  key={order.id}
              />
          ))
        : null;
};

const OrderDetailsComponent = ({ theme, labels, order, everyOther }) => {
    // Active order
    const [orderActive, setOrderActive] = useState(false);

    return (
        <div className="flex flex-col">
            <div
                onClick={() => setOrderActive(!orderActive)}
                className={cc([
                    'p-12 justify-between flex cursor-pointer hover:bg-pink hover:bg-opacity-40 transition-default t-h15 select-none',
                    {
                        'bg-gray bg-opacity-40': everyOther,
                        '!bg-black text-white': orderActive,
                    },
                ])}>
                <div className="w-[100px]">{order.number}</div>
                <div className="w-[100px]">
                    {dayjs(order.date_created).format('DD/MM/YY')}
                </div>
                <div className="hidden md:block w-[200px]">
                    {
                        labels.ACCOUNT.ORDERS.STATUS_KEYS[
                            order.status.toUpperCase()
                        ]
                    }
                </div>
                <div className="text-right w-[120px]">
                    {order.grand_total} {order.currency}
                </div>
            </div>
            <AnimateHeight duration={300} height={orderActive ? 'auto' : 0}>
                <div className="relative flex flex-col pt-48 lg:flex-row lg:justify-between">
                    <div
                        css={css`
                            ${s.grid.columns('width', {
                                0: 4,
                                20: 6,
                                30: 8,
                            })};
                        `}>
                        <BasketOverview cart={order} labels={labels} />
                    </div>
                    <div
                        css={css`
                            ${s.grid.columns('width', {
                                0: 4,
                                20: 6,
                                30: 3,
                            })};
                        `}>
                        <Summary
                            {...{
                                labels,
                                theme,
                                cartState: 5,
                                cart: order,
                            }}
                        />
                    </div>
                </div>
            </AnimateHeight>
        </div>
    );
};

OrdersComponent.propTypes = {
    labels: t.object,
};

OrdersComponent.defaultProps = {};

export default OrdersComponent;
