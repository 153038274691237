// React
import React, { useEffect } from 'react';

// Packages
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useCart, useAccount } from 'hooks';

// Components

import ContactInformation from 'components/_account/contactInformation';
import AddressInformation from 'components/_account/addressInformation';
import Button from 'components/button';
import Toggle from 'components/toggle';

const PersonalInformationComponent = ({ labels, theme }) => {
    // Hook: useAccount
    const {
        account,
        updateAccountInformation,
        loading: loadingAccount,
    } = useAccount();

    // Hook: useForm setup with provider
    const methods = useForm();
    const { handleSubmit, control } = methods;

    const watchDifferentBillingAddressToggle = useWatch({
        control,
        name: 'differentBillingAddressToggle',
    });

    function onSubmit(data) {
        const {
            billing_address1,
            billing_address2,
            billing_city,
            billing_country,
            billing_first_name,
            billing_last_name,
            billing_state,
            billing_zip,
            differentBillingAddressToggle,
            email,
            phone,
            shipping_address1,
            shipping_address2,
            shipping_city,
            shipping_country,
            shipping_first_name,
            shipping_last_name,
            shipping_state,
            shipping_zip,
        } = data;

        updateAccountInformation({
            email,
            shippingAddress: {
                first_name: shipping_first_name,
                last_name: shipping_last_name,
                address1: shipping_address1,
                address2: shipping_address2,
                city: shipping_city,
                state: shipping_state,
                zip: shipping_zip,
                country: shipping_country,
                phone: phone,
            },
            billingAddress: differentBillingAddressToggle
                ? {
                      first_name: billing_first_name,
                      last_name: billing_last_name,
                      address1: billing_address1,
                      address2: billing_address2,
                      city: billing_city,
                      state: billing_state,
                      zip: billing_zip,
                      country: billing_country,
                  }
                : {
                      first_name: null,
                      last_name: null,
                      address1: null,
                      address2: null,
                      city: null,
                      state: null,
                      zip: null,
                      country: null,
                  },
        });
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col space-y-24">
                        <ContactInformation
                            {...{
                                labels,
                                defaults: {
                                    email: account?.email,
                                    phone: account?.shipping?.phone,
                                },
                            }}
                        />

                        <AddressInformation
                            {...{
                                title: labels.ACCOUNT.ADDRESS_INFORMATION
                                    .TITLE_SHIPPING,
                                prefix: 'shipping',
                                labels,
                                required: true,
                                defaults: {
                                    first_name: account?.shipping?.first_name,
                                    last_name: account?.shipping?.last_name,
                                    address1: account?.shipping?.address1,
                                    address2: account?.shipping?.address2,
                                    zip: account?.shipping?.zip,
                                    city: account?.shipping?.city,
                                    state: account?.shipping?.state,
                                    country: account?.shipping?.country,
                                },
                            }}
                        />

                        <Toggle
                            {...{
                                control,
                                label: labels.ACCOUNT.ADDRESS_INFORMATION
                                    .SHOW_BILLING,
                                name: 'differentBillingAddressToggle',
                                defaultValue: !!account?.billing?.address1,
                            }}
                        />
                        <AnimateHeight
                            duration={300}
                            height={
                                watchDifferentBillingAddressToggle ? 'auto' : 0
                            }>
                            <AddressInformation
                                {...{
                                    title: labels.ACCOUNT.ADDRESS_INFORMATION
                                        .TITLE_BILLING,
                                    prefix: 'billing',
                                    labels,
                                    required:
                                        watchDifferentBillingAddressToggle,
                                    defaults: {
                                        first_name:
                                            account?.billing?.first_name,
                                        last_name: account?.billing?.last_name,
                                        address1: account?.billing?.address1,
                                        address2: account?.billing?.address2,
                                        zip: account?.billing?.zip,
                                        city: account?.billing?.city,
                                        state: account?.billing?.state,
                                        country: account?.billing?.country,
                                    },
                                }}
                            />
                        </AnimateHeight>
                    </div>
                    <div className="flex justify-end mt-12">
                        <Button
                            {...{
                                submit: true,
                                loading: loadingAccount,
                                theme,
                                label: labels.ACCOUNT.ADDRESS_INFORMATION.SAVE,
                                feedback:
                                    labels.ACCOUNT.ADDRESS_INFORMATION
                                        .SAVE_COMPLETE,
                            }}
                        />
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

export default PersonalInformationComponent;
