// React
import React, { useState } from 'react';

// Packages
import cc from 'classcat';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// Utilities
import { useSubscriptions } from 'hooks';

// Components
import Button from 'components/button';

const CardInformationComponent = ({
    subscription,
    labels,
    theme,
    onCancel,
}) => {
    // State: Loading
    const [loading, setLoading] = useState(false);

    // State: Error
    const [error, setError] = useState(false);
    const [stripeCardElement, setStripeCardElement] = useState(null);

    // Hook: useSubscriptions
    const { updateSubscriptionCard } = useSubscriptions();

    // Hook: Stripe
    const stripe = useStripe();
    const elements = useElements();

    async function submit() {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Loading
        setLoading(true);

        // Error
        setError(false);

        // Get payment method and update subscription card
        stripe
            .createPaymentMethod({
                type: 'card',
                card: elements.getElement('card'),
            })
            .then(({ paymentMethod }) => {
                return updateSubscriptionCard({
                    id: subscription.id,
                    card: {
                        token: paymentMethod?.id,
                        last4: paymentMethod?.card?.last4,
                        exp_month: paymentMethod?.card?.exp_month,
                        exp_year: paymentMethod?.card?.exp_year,
                        brand: paymentMethod?.card?.brand,
                        address_check:
                            paymentMethod?.card?.checks.address_line1_check,
                        cvc_check: paymentMethod?.card?.checks.cvc_check,
                        zip_check:
                            paymentMethod?.card?.checks.address_zip_check,
                    },
                });
            })
            .then(() => {
                // Loading
                setLoading(false);
                setTimeout(() => {
                    onCancel();
                }, 700);
            })
            .catch(error => {
                console.warn(error);

                // Loading
                setLoading(false);

                // Error
                setError(true);
            });
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col space-y-8 ">
                <div
                    className={cc([
                        'flex-grow py-[13px] outline-none bg-opacity-40 bg-gray px-14 t-h15',
                        {
                            'ring-2 ring-error': error,
                        },
                    ])}>
                    <CardElement />
                </div>
            </div>
            <div className="flex justify-end mt-48 space-x-12">
                <Button
                    {...{
                        onClick() {
                            onCancel();
                        },
                        loading,
                        type: 'secondary',
                        theme,
                        label: labels.ACCOUNT.SUBSCRIPTIONS
                            .CHANGE_ADDRESS_CANCEL,
                    }}
                />
                <Button
                    {...{
                        onClick() {
                            submit();
                        },
                        loading,
                        disabled: !stripe,
                        theme,
                        label: labels.ACCOUNT.ADDRESS_INFORMATION.SAVE,
                        feedback:
                            labels.ACCOUNT.ADDRESS_INFORMATION.SAVE_COMPLETE,
                    }}
                />
            </div>
        </div>
    );
};

CardInformationComponent.propTypes = {};

CardInformationComponent.defaultProps = {};

export default CardInformationComponent;
