// React
import React, { useState, useEffect } from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Emotion / styling
import { css } from '@emotion/react';
import s from 'src/styles';

// Utilities
import { useSubscriptions, useSubscriptionDates } from 'hooks';

// Components
import SubscriptionAddress from 'components/_account/subscriptionAddress';
import CardInformation from 'components/_account/cardInformation';
import Select from 'components/_input/select';

// Stripe
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const SubscriptionComponent = ({ labels, theme, locale }) => {
    // Hook: Subscriptions
    const { subscriptions } = useSubscriptions();

    return (
        <div>
            <h3 className="mb-24 text-black t-h7 text-opacity-60">
                {labels.ACCOUNT.SUBSCRIPTIONS.TITLE}{' '}
            </h3>
            <div className="my-24 space-y-24">
                {subscriptions &&
                    subscriptions.length > 0 &&
                    subscriptions.map(subscription => {
                        return (
                            <SubscriptionDetailsComponent
                                {...{
                                    subscription,
                                    labels,
                                    theme,
                                }}
                                key={subscription?.id}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

const SubscriptionDetailsComponent = ({ theme, labels, subscription }) => {
    // Hook: Subscriptions
    const { changeInvoiceDate, cancel, loading } = useSubscriptions();

    // Hook: useForm setup with provider
    const methods = useForm();
    const { control } = methods;

    // Watch the select
    const nextDeliveryDate = useWatch({
        control,
        name: 'next_delivery',
        defaultValue: '',
    });

    // Hook: Subscription dates
    const {
        formatDate,
        getListOfDeliveryDays,
        getBillingDate,
        getDeliveryDate,
        pausedDate,
        isPaused,
    } = useSubscriptionDates();

    // State: Handle address change
    const [showAddress, setShowAddress] = useState(false);

    // State: Handle address change
    const [showCard, setShowCard] = useState(false);

    // State: Cancel confirm
    const [showCancel, setShowCancel] = useState(false);

    // Effect: React to next delivery date
    useEffect(() => {
        if (nextDeliveryDate) {
            changeInvoiceDate({
                id: subscription?.id,
                date: getBillingDate(nextDeliveryDate).format(),
            });
        }
    }, [nextDeliveryDate]);

    // Notes array for snipcart orders
    // Array: 0: Snipcart, 1: ShippingNumber, 2: Subscription type, 3: Interval
    const notesArray = subscription?.notes?.split('|') ?? [];

    // Type of subscription (Snipcart (migrated), Swell)
    const subscriptionType = subscription?.order_item
        ? 'SWELL'
        : notesArray[0] === 'SNIPCART'
        ? 'SNIPCART'
        : 'OTHER';

    // Get type of subscription delivery type (SURPRICE / DECIDE)
    const deliveryType =
        subscriptionType === 'SWELL'
            ? subscription?.order_item?.metadata?.product?.type[0]
            : notesArray[2] ?? null;

    // Additional shipping information
    const shipping = subscription?.webshipper
        ? JSON.parse(subscription?.webshipper)
        : subscription?.order_item?.metadata?.shipping ?? null;

    // Status not yet started
    const notYetStarted =
        subscription?.status.toUpperCase() === 'TRIAL' &&
        subscription?.invoices?.count === 0;

    return subscription?.canceled ? null : (
        <div className="flex flex-col">
            <div
                className={cc([
                    'p-12 justify-between flex bg-gray bg-opacity-80 t-h12',
                ])}>
                <div>{subscription?.product?.name}</div>
                <div className="hidden md:block">
                    {isPaused(
                        subscription?.date_trial_end,
                        subscription?.paused
                    )
                        ? labels.ACCOUNT.SUBSCRIPTIONS.STATUS_KEYS.PAUSED
                        : `${
                              labels.ACCOUNT.SUBSCRIPTIONS.STATUS_KEYS[
                                  subscription?.status.toUpperCase()
                              ]
                          } ${
                              subscription?.status.toUpperCase() === 'TRIAL'
                                  ? formatDate(
                                        getDeliveryDate(
                                            subscription?.date_trial_end
                                        )
                                    )
                                  : ''
                          }`}
                </div>
            </div>
            <div className="flex flex-col p-12 pb-24 bg-gray bg-opacity-20 t-h15">
                <div className="flex flex-col space-y-24 md:flex-row md:space-x-96 md:space-y-0">
                    {/* Details */}
                    <div className="flex flex-col space-y-8 text-black t-h15">
                        <span className="text-black t-h11 text-opacity-60">
                            <span>{labels.ACCOUNT.SUBSCRIPTIONS.DETAILS}</span>
                        </span>
                        <span className="flex flex-col space-y-2">
                            <span className="font-bold">
                                {labels.ACCOUNT.SUBSCRIPTIONS.PRICE}
                            </span>
                            <span>
                                {`${subscription?.grand_total} ${subscription?.currency}`}
                            </span>
                        </span>
                        <span className="flex flex-col space-y-2 ">
                            <span className="font-bold">
                                {labels.ACCOUNT.SUBSCRIPTIONS.INTERVAL}
                            </span>
                            <span>
                                {subscription?.interval_count}{' '}
                                {labels.ACCOUNT.SUBSCRIPTIONS.WEEKS}
                            </span>
                        </span>
                        {subscription?.date_trial_end && (
                            <span className="flex flex-col space-y-2">
                                <span className="font-bold">
                                    {labels.ACCOUNT.SUBSCRIPTIONS.NEXT_DELIVERY}
                                </span>
                                <span>
                                    {isPaused(
                                        subscription?.date_trial_end,
                                        subscription?.paused
                                    )
                                        ? labels.ACCOUNT.SUBSCRIPTIONS
                                              .STATUS_KEYS.PAUSED
                                        : formatDate(
                                              getDeliveryDate(
                                                  subscription?.date_trial_end
                                              )
                                          )}
                                </span>
                            </span>
                        )}
                        <span className="flex flex-col space-y-2 ">
                            <span className="font-bold">
                                {labels.ACCOUNT.SUBSCRIPTIONS.PAYMENT_DETAILS}
                            </span>
                            <span>
                                {subscription?.billing?.card?.brand?.toUpperCase()}
                                , *{subscription?.billing?.card?.last4} (
                                {subscription?.billing?.card?.exp_month}/
                                {subscription?.billing?.card?.exp_year
                                    ?.toString()
                                    .slice(-2)}
                                )
                                <button
                                    className="self-start ml-12 underline t-h17"
                                    onClick={() => setShowCard(!showCard)}>
                                    {showCard
                                        ? labels.ACCOUNT.SUBSCRIPTIONS
                                              .CHANGE_ADDRESS_CANCEL
                                        : labels.ACCOUNT.SUBSCRIPTIONS
                                              .CHANGE_ADDRESS}
                                </button>
                            </span>
                        </span>
                    </div>

                    {/* Shipping */}
                    <div className="flex flex-col space-y-8">
                        <span className="flex items-baseline text-black t-h11">
                            <span className="text-black text-opacity-60">
                                {labels.ACCOUNT.SUBSCRIPTIONS.SHIPPING_ADDRESS}
                            </span>
                        </span>
                        <span className="flex flex-col space-y-2 text-black t-h15">
                            <span className="font-bold">
                                {subscription?.shipping?.name}
                            </span>
                            <span>
                                {subscription?.shipping?.address1}
                                {subscription?.shipping?.address2 &&
                                    `, ${subscription?.shipping?.address2}`}
                            </span>
                            <span>
                                {subscription?.shipping?.zip}{' '}
                                {subscription?.shipping?.city}
                            </span>
                            <span>
                                {subscription?.shipping?.country}{' '}
                                {subscription?.shipping?.state &&
                                    ` (${subscription?.shipping?.state})`}
                            </span>
                            {shipping && (
                                <span>
                                    {shipping.shipping_rate.name}
                                    <br />
                                    {shipping.drop_point?.name}
                                </span>
                            )}
                            {subscriptionType === 'SWELL' ? (
                                <button
                                    className="!mt-12 self-start t-h17 underline"
                                    onClick={() =>
                                        setShowAddress(!showAddress)
                                    }>
                                    {showAddress
                                        ? labels.ACCOUNT.SUBSCRIPTIONS
                                              .CHANGE_ADDRESS_CANCEL
                                        : labels.ACCOUNT.SUBSCRIPTIONS
                                              .CHANGE_ADDRESS}
                                </button>
                            ) : (
                                <p className="t-h18 max-w-[160px] !mt-16">
                                    {
                                        labels.ACCOUNT.SUBSCRIPTIONS
                                            .MIGRATED_SHIPPING
                                    }
                                </p>
                            )}
                        </span>
                    </div>

                    {/* Edit */}
                    <div className="flex flex-col space-y-8">
                        <span className="flex items-baseline text-black t-h11">
                            <span className="text-black text-opacity-60">
                                {labels.ACCOUNT.SUBSCRIPTIONS.EDIT}
                                {notYetStarted && (
                                    <span className="ml-8 t-h15">
                                        (
                                        {
                                            labels.ACCOUNT.SUBSCRIPTIONS
                                                .NOT_STARTED_YET
                                        }
                                        )
                                    </span>
                                )}
                            </span>
                        </span>
                        {subscriptionType === 'SWELL' ||
                        subscriptionType === 'SNIPCART' ? (
                            <FormProvider {...methods}>
                                <Select
                                    {...{
                                        loading,
                                        input: {
                                            namespace:
                                                labels.ACCOUNT.SUBSCRIPTIONS
                                                    .INPUTS.NEXT_DELIVERY,
                                            name: 'next_delivery',
                                            defaultValue: isPaused(
                                                subscription?.date_trial_end,
                                                subscription?.paused
                                            )
                                                ? ''
                                                : getDeliveryDate(
                                                      subscription?.date_trial_end
                                                  ).format(),
                                            type: 'select',
                                            disabled: notYetStarted,
                                            options: [
                                                {
                                                    value: pausedDate.format(),
                                                    label: labels.ACCOUNT
                                                        .SUBSCRIPTIONS.INPUTS
                                                        .NEXT_DELIVERY
                                                        .LABEL_PAUSED,
                                                },
                                                ...getListOfDeliveryDays(
                                                    deliveryType
                                                ).map((day, index) => ({
                                                    value: day.format(),
                                                    label:
                                                        index === 0
                                                            ? `${formatDate(
                                                                  day
                                                              )} (${
                                                                  labels.ACCOUNT
                                                                      .SUBSCRIPTIONS
                                                                      .INPUTS
                                                                      .NEXT_DELIVERY
                                                                      .LABEL_NEXT_POSSIBLE
                                                              })`
                                                            : formatDate(day),
                                                })),
                                            ],
                                        },
                                    }}
                                />
                            </FormProvider>
                        ) : (
                            <div className="flex flex-col">
                                <p className={'opacity-50 t-h17 mb-8'}>
                                    {
                                        labels.ACCOUNT.SUBSCRIPTIONS.INPUTS
                                            .NEXT_DELIVERY.LABEL
                                    }
                                </p>
                                <p className="font-bold text-black t-h15">
                                    {formatDate(
                                        getDeliveryDate(
                                            subscription?.date_trial_end
                                        )
                                    )}
                                </p>
                                <p className="t-h18 max-w-[160px] !mt-16">
                                    {
                                        labels.ACCOUNT.SUBSCRIPTIONS
                                            .MIGRATED_DELIVERY
                                    }
                                </p>
                            </div>
                        )}

                        <span className="flex flex-col mt-12 text-black t-h15">
                            {showCancel ? (
                                <div className="flex flex-col mt-18 ">
                                    <span className="mb-8">
                                        {
                                            labels.ACCOUNT.SUBSCRIPTIONS
                                                .CANCEL_CONFIRM
                                        }
                                    </span>
                                    <div className="space-x-24">
                                        <button
                                            className="self-start underline t-h17 text-error"
                                            onClick={() =>
                                                cancel({
                                                    id: subscription?.id,
                                                })
                                            }>
                                            {
                                                labels.ACCOUNT.SUBSCRIPTIONS
                                                    .CANCEL_YES
                                            }
                                        </button>
                                        <button
                                            className="self-start underline t-h17"
                                            onClick={() =>
                                                setShowCancel(false)
                                            }>
                                            {
                                                labels.ACCOUNT.SUBSCRIPTIONS
                                                    .CANCEL_NO
                                            }
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="self-start underline mt-18 t-h17"
                                    onClick={() => setShowCancel(true)}>
                                    {labels.ACCOUNT.SUBSCRIPTIONS.CANCEL}
                                </button>
                            )}
                        </span>
                    </div>
                </div>

                {/* Edit shipping settings */}
                <AnimateHeight
                    className="-mx-12"
                    duration={300}
                    height={showAddress ? 'auto' : 0}>
                    <div className="py-24 mt-24 bg-white">
                        <div
                            css={css`
                                ${s.grid.columns('width', {
                                    0: 4,
                                    20: 6,
                                    30: 8,
                                })};
                            `}>
                            <SubscriptionAddress
                                {...{
                                    subscription,
                                    labels,
                                    theme,
                                    onCancel() {
                                        setShowAddress(false);
                                    },
                                }}
                            />
                        </div>
                    </div>
                </AnimateHeight>

                {/* Edit card information */}
                <AnimateHeight
                    className="-mx-12"
                    duration={300}
                    height={showCard ? 'auto' : 0}>
                    <div className="py-24 mt-24 bg-white">
                        <div
                            css={css`
                                ${s.grid.columns('width', {
                                    0: 4,
                                    20: 6,
                                    30: 8,
                                })};
                            `}>
                            <Elements stripe={stripePromise}>
                                <CardInformation
                                    {...{
                                        subscription,
                                        labels,
                                        theme,
                                        onCancel() {
                                            setShowCard(false);
                                        },
                                    }}
                                />
                            </Elements>
                        </div>
                    </div>
                </AnimateHeight>
            </div>
        </div>
    );
};

SubscriptionComponent.propTypes = {
    labels: t.object,
};

SubscriptionComponent.defaultProps = {};

export default SubscriptionComponent;
