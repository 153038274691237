// React
import React, { useEffect, useState } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import { css } from '@emotion/react';
import s from 'src/styles';
import cc from 'classcat';

// Packages
import _get from 'lodash.get';
import { StickyContainer } from 'react-sticky';

// Utilities
import { localize, scrollTo } from 'utilities';
import { useTheme, useAccount } from 'hooks';

// Components
import SEO from 'components/seo';
import { PageNavigation } from 'components/pageNavigation';
import { PageNavigationSticky } from 'components/pageNavigation';
import Login from 'components/_account/login';
import PersonalInformation from 'components/_account/personalInformation';
import Orders from 'components/_account/orders';
import Subscriptions from 'components/_account/subscriptions';

const AccountPageComponent = ({ pageContext, content, location }) => {
    const { locale } = pageContext;
    const { seoMetaTags } = content;

    // Hook: Account
    const { account, loading } = useAccount();

    // Set theme
    const theme = useTheme(s.color('white'));

    // Labels
    const labels = {
        ACCOUNT: JSON.parse(content.labels),
        CART: JSON.parse(content.cart.labels),
    };

    // Define categories
    const categories = [
        { name: labels.ACCOUNT.CATEGORY_ORDERS, category: 'ORDERS' },
        { name: labels.ACCOUNT.CATEGORY_SETTINGS, category: 'SETTINGS' },
    ];

    // Category
    const [category, setCategory] = useState(categories[0]);

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                {!account && (
                    <>
                        <div className="mb-32 pt-100 lg:pt-140">
                            <PageNavigation {...{ theme }}>
                                <span>{labels.ACCOUNT.TITLE}</span>
                            </PageNavigation>
                        </div>
                        <div
                            css={css`
                                ${s.grid.columns('width', {
                                    0: 4,
                                    20: 6,
                                    30: 8,
                                })};
                            `}>
                            <Login
                                {...{
                                    locale,
                                    labels,
                                    theme,
                                    location,
                                }}
                            />
                        </div>
                    </>
                )}
                {account && (
                    <StickyContainer>
                        <PageNavigationSticky {...{ theme }}>
                            {categories.map(item => (
                                <button
                                    className={cc([
                                        'cursor-pointer text-black !transition-default whitespace-nowrap opacity-30 hover:opacity-100',
                                        {
                                            '!opacity-100':
                                                item.name ===
                                                _get(category, 'name', null),
                                        },
                                    ])}
                                    key={item.name}
                                    onClick={() => {
                                        scrollTo(0);
                                        setCategory(item);
                                    }}>
                                    {item.name}
                                </button>
                            ))}
                        </PageNavigationSticky>
                        <div className="pt-96 md:pt-112 lg:pt-48 mt-80 lg:mt-100">
                            {category.category === 'ORDERS' && (
                                <div className="space-y-48 mb-128">
                                    <Subscriptions
                                        {...{
                                            locale,
                                            labels,
                                            theme,
                                        }}
                                    />
                                    <Orders
                                        {...{
                                            locale,
                                            labels,
                                            theme,
                                        }}
                                    />
                                </div>
                            )}
                            <div
                                css={css`
                                    ${s.grid.columns('width', {
                                        0: 4,
                                        20: 6,
                                        30: 8,
                                    })};
                                `}>
                                {category.category === 'SETTINGS' && (
                                    <div className="space-y-48 mb-128">
                                        <Login
                                            {...{
                                                locale,
                                                labels,
                                                theme,
                                            }}
                                        />
                                        <PersonalInformation
                                            {...{
                                                labels,
                                                theme,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </StickyContainer>
                )}
            </s.layout.PageContainer>
        </>
    );
};

const NamedAccountPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageCart, allDatoCmsPageAccount } =
        useStaticQuery(graphql`
            query {
                allDatoCmsPageAccount {
                    edges {
                        node {
                            locale
                            labels
                            seoMetaTags {
                                ...SEO
                            }
                        }
                    }
                }
                allDatoCmsPageCart {
                    edges {
                        node {
                            locale
                            labels
                        }
                    }
                }
            }
        `);

    return (
        <AccountPageComponent
            {...{
                content: {
                    ...localize(allDatoCmsPageAccount, locale),
                    cart: localize(allDatoCmsPageCart, locale),
                },
                ...props,
            }}
        />
    );
};

export default NamedAccountPageComponent;
