// React
import React, { useState, useEffect } from 'react';

// Packages
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useSubscriptions, useShipping } from 'hooks';

// Components
import AddressInformation from 'components/_account/addressInformation';
import Button from 'components/button';
import Select from 'components/_input/select';

const calculatePrice = (itemPrice, shippingPrice, shippingTax) => {
    let price = itemPrice;
    if (shippingPrice > 0) {
        price = itemPrice + shippingPrice;
        if (shippingTax > 0) {
            // 20/(1+(25/100))
            price = itemPrice + shippingPrice / (1 + shippingTax / 100);
        }
    }
    return price;
};

const PersonalInformationComponent = ({
    subscription,
    labels,
    theme,
    onCancel,
}) => {
    // Hook: useSubscriptions
    const { updateSubscriptionDetails, updateSubscriptionShipping } =
        useSubscriptions();

    // Hook: useShipping
    const {
        getSubscriptionQuotes,
        getDropPoints,
        loading: quotesLoading,
    } = useShipping();

    // State: Quotes
    const [quotes, setQuotes] = useState([]);

    // State: Loading
    const [loading, setLoading] = useState(false);

    // Hook: useForm setup with provider
    const methods = useForm();
    const { handleSubmit, control } = methods;

    const watchSubscriptionAddress = useWatch({
        control,
        name: 'subscription_address1',
        defaultValue: subscription?.shipping?.address1,
    });

    const watchSubscriptionZip = useWatch({
        control,
        name: 'subscription_zip',
        defaultValue: subscription?.shipping?.zip,
    });

    const watchSubscriptionCountry = useWatch({
        control,
        name: 'subscription_country',
        defaultValue: subscription?.shipping?.country,
    });

    const watchSubscriptionCity = useWatch({
        control,
        name: 'subscription_city',
        defaultValue: subscription?.shipping?.city,
    });

    function onSubmit(data) {
        const {
            subscription_address1,
            subscription_address2,
            subscription_city,
            subscription_country,
            subscription_first_name,
            subscription_last_name,
            subscription_state,
            subscription_zip,
            shipping_select_subscription,
            shipping_select_droppoint_subscription,
        } = data;

        // Loading
        setLoading(true);

        // Get shipping info from quotes
        const subscriptionShipping = quotes[shipping_select_subscription];

        // Check for drop point
        const subscriptionShippingDropPoint = subscriptionShipping
            ?.shipping_rate?.require_drop_point
            ? JSON.parse(shipping_select_droppoint_subscription)
            : null;

        // Calculate new price
        const newPrice = calculatePrice(
            subscription?.order_item?.orig_price,
            subscriptionShipping.price,
            subscriptionShipping.tax_percent
        );

        // Update address, price and shipping info
        updateSubscriptionDetails({
            id: subscription.id,
            shippingAddress: {
                first_name: subscription_first_name,
                last_name: subscription_last_name,
                address1: subscription_address1,
                address2: subscription_address2,
                city: subscription_city,
                state: subscription_state,
                zip: subscription_zip,
                country: subscription_country,
            },
            newPrice,
            shipping: {
                ...subscriptionShipping,
                drop_point: subscriptionShippingDropPoint,
            },
        })
            // .then(() =>
            //     updateSubscriptionShipping({
            //         orderId: subscription?.order_id,
            //         orderItemId: subscription?.order_item_id,
            //         newPrice,
            //         data: {
            //             ...subscriptionShipping,
            //             drop_point: subscriptionShippingDropPoint,
            //         },
            //     })
            // )
            .then(() => {
                // Loading
                setLoading(false);
                setTimeout(() => {
                    onCancel();
                }, 700);
            });
    }

    // Effect: Reset on change
    useEffect(() => {
        setQuotes([]);
    }, [
        watchSubscriptionAddress,
        watchSubscriptionZip,
        watchSubscriptionCountry,
        watchSubscriptionCity,
    ]);

    async function refreshQuotes() {
        if (
            watchSubscriptionAddress &&
            watchSubscriptionZip &&
            watchSubscriptionCountry &&
            watchSubscriptionCity
        ) {
            const q = await getSubscriptionQuotes({
                price: subscription?.grand_total,
                weight: subscription?.order_item?.shipment_weight,
                address: watchSubscriptionAddress,
                zip: watchSubscriptionZip,
                country: watchSubscriptionCountry,
                city: watchSubscriptionCity,
            });

            setQuotes(q);
        }
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col">
                        <AddressInformation
                            {...{
                                prefix: 'subscription',
                                labels,
                                required: true,
                                defaults: {
                                    first_name:
                                        subscription?.shipping?.first_name,
                                    last_name:
                                        subscription?.shipping?.last_name,
                                    address1: subscription?.shipping?.address1,
                                    address2: subscription?.shipping?.address2,
                                    zip: subscription?.shipping?.zip,
                                    city: subscription?.shipping?.city,
                                    state: subscription?.shipping?.state,
                                    country: subscription?.shipping?.country,
                                },
                            }}
                        />
                        <div className="flex flex-col space-y-12 md:space-y-0 md:flex-row md:justify-between">
                            <ShippingSelectComponent
                                {...{
                                    // Shared
                                    title: subscription?.order_item?.metadata
                                        ?.product?.name,
                                    control,
                                    parentLoading: quotesLoading,
                                    // Shipping method
                                    shippingMethodNamespace:
                                        labels.CART.SECTIONS.SHIPPING.INPUTS
                                            .SHIPPING_SELECT_SUBSCRIPTION,
                                    shippingMethodInputName: `shipping_select_subscription`,
                                    required: true,
                                    quoteOptions: quotes,
                                    // Drop point
                                    dropPointMethodNamespace:
                                        labels.CART.SECTIONS.SHIPPING.INPUTS
                                            .DROPPOINT_SELECT,
                                    dropPointMethodInputName: `shipping_select_droppoint_subscription`,
                                    getDropPoints,
                                    address: {
                                        address: watchSubscriptionAddress,
                                        zip: watchSubscriptionZip,
                                        country: watchSubscriptionCountry,
                                        city: watchSubscriptionCity,
                                    },
                                }}
                            />
                            <Button
                                {...{
                                    className: 'self-end',
                                    onClick() {
                                        refreshQuotes();
                                    },
                                    loading: quotesLoading,
                                    type: 'secondary',
                                    theme,
                                    label: labels.ACCOUNT.SUBSCRIPTIONS
                                        .REFRESH_QUOTES,
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-48 space-x-12">
                        <Button
                            {...{
                                onClick() {
                                    onCancel();
                                },
                                loading,
                                type: 'secondary',
                                theme,
                                label: labels.ACCOUNT.SUBSCRIPTIONS
                                    .CHANGE_ADDRESS_CANCEL,
                            }}
                        />
                        <Button
                            {...{
                                submit: true,
                                loading,
                                theme,
                                label: labels.ACCOUNT.ADDRESS_INFORMATION.SAVE,
                                feedback:
                                    labels.ACCOUNT.ADDRESS_INFORMATION
                                        .SAVE_COMPLETE,
                            }}
                        />
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

const ShippingSelectComponent = ({
    // Shared
    title,
    control,
    parentLoading,
    // Shipping method
    shippingMethodNamespace,
    shippingMethodInputName,
    required,
    quoteOptions,
    // Drop point
    dropPointMethodNamespace,
    dropPointMethodInputName,
    getDropPoints,
    address,
}) => {
    // State: Drop points
    const [showDropPoints, setShowDropPoints] = useState(false);
    const [dropPointOptions, setDropPointOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    // Watch the select for shipping drop points to make drop points required
    const watchShippingSelect = useWatch({
        control,
        name: shippingMethodInputName,
        defaultValue: '',
    });

    // Effect: React to watchShippingSelect to check for drop points
    useEffect(async () => {
        if (quoteOptions.length > 0 && watchShippingSelect) {
            // Loading
            setLoading(true);
            // Reset drop points
            setShowDropPoints(false);
            const shipping = quoteOptions[watchShippingSelect];
            if (shipping.shipping_rate.require_drop_point) {
                // Set immediate required
                setShowDropPoints(true);

                // Fetch drop points
                const data = await getDropPoints({
                    carrier: shipping.shipping_rate.carrier_id,
                    service: shipping.shipping_rate.carrier_service_code,
                    ...address,
                });
                setDropPointOptions(data);
            } else {
                // Set immediate required
                setShowDropPoints(false);
            }
            // Loading
            setLoading(false);
        }
    }, [watchShippingSelect, quoteOptions]);

    // Effect: Reset when quote options changes
    useEffect(() => {
        if (quoteOptions.length === 0) {
            setShowDropPoints(false);
            setDropPointOptions([]);
        }
    }, [quoteOptions]);

    return (
        <div className="flex flex-col items-start">
            {title && <h4 className="mb-8 t-h14">{title}</h4>}
            <Select
                {...{
                    loading: loading || parentLoading,
                    input: {
                        namespace: shippingMethodNamespace,
                        name: shippingMethodInputName,
                        type: 'select',
                        required,
                        options:
                            quoteOptions?.length > 0
                                ? quoteOptions?.map((item, index) => ({
                                      value: index,
                                      label: `${item.shipping_rate.name} (${item.price} ${item.currency})`,
                                  }))
                                : [],
                    },
                }}
            />

            {/* Show if there are drop points related to the shipping option */}
            <AnimateHeight duration={300} height={showDropPoints ? 'auto' : 0}>
                <div className="flex flex-col items-start mt-16">
                    <Select
                        {...{
                            loading: loading || parentLoading,
                            input: {
                                namespace: dropPointMethodNamespace,
                                name: dropPointMethodInputName,
                                type: 'select',
                                disabled:
                                    dropPointOptions.length === 0 ||
                                    loading ||
                                    parentLoading,
                                required: showDropPoints,
                                options: dropPointOptions.map(item => ({
                                    value: JSON.stringify(item),
                                    label: `${item.name} (${item.address_1}, ${
                                        item.zip
                                    } ${item.city}) - ${item.distance.toFixed(
                                        1
                                    )} km`,
                                })),
                            },
                        }}
                    />
                </div>
            </AnimateHeight>
        </div>
    );
};

export default PersonalInformationComponent;
